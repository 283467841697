import {
    Abstract
} from './Abstract';

export class AccountingGroupType extends Abstract {
    constructor(session) {
        super('accounting/AccountingGroupType', session);
        this.name = null;
        this.key_name = null;
    }
}